<template lang="pug">
.page-filter.text-sm-right
  span.custom-select-title.m-r-10 {{ $t("sort.sortCars") }}
  select.custom-select.w-a.d-inline-block(
    v-model="selectedTypeOfSort"
    @change="getVehicles(false)"
  )
    option(
      v-for="option in sortedBy"
      :value="option.value"
      :selected="selectedTypeOfSort === option.value"
    ) {{ $t(option.translationKey) }}
</template>

<script>
	import {mapActions, mapState, } from 'vuex';

  export default {
    name: 'VHeaderSort',
    data: () => ({
      sortOptions: [
        {
          label: 'Pris, lägst överst',
          translationKey: 'sort.priceLowestTop',
          value: 'asc',
          type: 'price',
        },
        {
          label: 'Pris, högst överst',
          translationKey: 'sort.priceHighestTop',
          value: 'desc',
          type: 'price',
        },
        {
          label: 'Kort leveranstid',
          translationKey: 'sort.shortDelivery',
          value: 'delivery_asc',
          type: 'delivery',
        },
        {
          label: 'Lång leveranstid',
          translationKey: 'sort.longDelivery',
          value: 'delivery_desc',
          type: 'delivery',
        },
        {
          label: 'Genom kampanjer',
          translationKey: 'sort.campaigns',
          value: 'campaign',
          type: 'campaign',
        }
      ],
    }),
    computed: {
      ...mapState('reseller', {
        hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      }),
      selectedTypeOfSort: {
        get() {
          return this.$store.state.list.selectedTypeOfSort
        },
        set(value) {
          this.$store.commit('list/setSelectedTypeOfSort', value)
        }
      },
      sortedBy() {
        return this.hasDeliveryTime ? this.sortOptions : this.sortOptions.filter(item => item.type !== "delivery");
      }
    },
		methods: {
      ...mapActions('list', ['getVehicles'])
    }
  }
</script>
